@import 'src/styles/breakpoints';

.nj-modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--nj-nav--pressed-background);
  backdrop-filter: blur(4px);
  z-index: 10200;
  display: flex;
  justify-content: space-between;

  .nj-modal-overlay-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .nj-modal-overlay-content-header {
      width: 100%;
      box-sizing: border-box;
      display: flex;
      padding: 16px 24px;
      justify-content: space-between;
      gap: 24px;

      .nj-modal-overlay-content-title-wrapper {
        display: flex;
        align-items: center;
        gap: 24px;
        max-width: 50vw;

        .nj-modal-gallery-title-text {
          font-size: 18px;
          font-weight: 600;
          line-height: 24px;
          margin: 0;
          padding: 0;
        }

        .nj-modal-gallery-title-icon {
          border-radius: 20px;
          background: var(--nj-background);
          box-shadow: 0 2px 4px 0 var(--nj-deep-shadow);
          display: flex;
          padding: 8px;
          justify-content: center;
          align-items: center;
          gap: 8px;
        }
      }

      .nj-modal-overlay-content-activity-buttons-wrapper {
        display: flex;
        gap: 24px;
      }
    }

    .nj-modal-overlay-content-body {
      display: flex;
      flex-direction: column;
      gap: 24px;
      box-sizing: border-box;
      justify-content: center;
      align-items: center;
      max-height: 100%;
      width: calc(
              100vw - 152px
      ); // 152px - width of right side panel for images/videos modal
      height: calc(100% - 72px);
      padding: 24px;
      flex-grow: 1;

      &.wide {
        width: calc(
                100vw - 240px
        ); // 240px - width of right side panel for other types of modal
      }

      @media only screen and (max-width: $nj-breakpoint-lower-than-desktop) {
        width: 100vw !important;
      }

      .nj-modal-content--carousel-container {
        width: 100%;
        height: 100%;
      }

      .nj-modal-gallery-player {
        & iframe {
          border-radius: 24px;
        }
      }

      .nj-modal-gallery-image {
        border-radius: 24px;
        height: 90%;
        width: auto;
        box-shadow: 0px 8px 24px 0px rgba(17, 24, 27, 0.24);
        @media screen and (max-width: $nj-breakpoint-lower-than-desktop) {
          height: auto;
          width: 95%;
        }
      }

      .nj-modal-broken-image {
        background: var(--nj-background);
        /* Rising shadow/Modal */
        box-shadow: 0px 8px 24px 0px rgba(17, 24, 27, 0.24);
        border-radius: 24px;
        height: 90%;
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .nj-modal-broken-image-text {
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: 32px;
        }
        @media screen and (max-width: $nj-breakpoint-lower-than-desktop) {
          height: auto;
          width: 95%;
        }
      }
    }

    .nj-modal-overlay-content-footer {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 24px;
      width: 100%;

      .swiper-next-button,
      .swiper-prev-button {
        position: relative;
        width: 40px;
        height: 40px;
        border-radius: 20px;
      }
    }
  }

  .nj-modal-overlay-right-side {
    box-sizing: border-box;
    background-color: var(--nj-panel);
    display: flex;
    padding: 16px;
    flex-direction: column;
    overflow-y: scroll;
    gap: 8px;
    width: auto;
  }
}

.nj-modal-overlay-list-item-wrapper {
  width: 100%;
  display: flex;

  &.selected .nj-gradient-overlay {
    content: "";
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
    background-color: var(--nj-nav--pressed-background);
    background-size: 100% 100%;
    background-position: 0 0;
    z-index: 1;
    border-radius: 8px;
  }

  .nj-generated-image-wrapper {
    display: flex;
    padding-bottom: 24px;
    flex-direction: column;
    align-items: center;
    border-radius: 16px;
    width: 168px;
    box-sizing: border-box;

    .nj-generated-image-title {
      display: flex;
      padding: 10px 16px;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;
      width: 100%;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: var(--nj-foreground--link);
      margin: 0;
      max-width: 130px;
    }
  }

  &.selected .nj-generated-image-wrapper {
    background-color: var(--nj-tab--background-selected);

    .nj-gradient-overlay {
      display: none;
    }
  }
}

.swiper-slide {
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.slide-content {
  width: 80%;
  text-align: center;
  padding: 20px;
}

.swiper-prev-button,
.swiper-next-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  cursor: pointer;
  display: flex;
  width: 48px;
  height: 48px;
  padding: 8px;
  align-items: flex-start;
  gap: 8px;
  border-radius: 24px;
  box-shadow: var(--nj-deep-shadow);
  color: var(--nj-foreground);
  background-color: var(--nj-panel);

  &:disabled {
    opacity: 0.38;
    cursor: not-allowed;
  }
}

.swiper-prev-button {
  left: 0;
}

.swiper-next-button {
  right: 0;
}

.nj-modal-gallery-reference-wrapper {
  width: 70%;
  height: 90%;
  background-color: var(--nj-background);
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  backdrop-filter: blur(16px);
  border-radius: 24px;
  box-shadow: 0 4px 8px 0 var(--nj-deep-shadow);

  & > iframe {
    border: none;
  }

  .nj-modal-gallery-reference-loading {
    height: 100%;
    background-color: var(--nj-background) !important;
    border-radius: 24px;
  }
}

.nj-modal-gallery-external-models-wrapper {
  width: 60%;
  height: 90%;
  background-color: var(--nj-background);
  padding: 24px;
  backdrop-filter: blur(16px);
  border-radius: 24px;
  box-shadow: 0 4px 8px 0 var(--nj-deep-shadow);
  overflow-y: scroll;

  @media only screen and (max-width: $nj-breakpoint-lower-than-desktop) {
    width: 90%;
    height: 80%;
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 16px 0;
    line-height: 28px;
    font-size: 16px;
  }

  a {
    text-decoration: underline;
  }

  .nj-modal-gallery-external-models-title {
    display: flex;
    align-items: center;
    gap: 24px;
    margin-bottom: 16px;
  }

  .nj-modal-gallery-external-models-title-text {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    padding: 0;
    margin: 0;
  }
}

.nj-modal-gallery-activity-button {
  width: 48px;
  height: 48px;
  padding: 8px;
  border-radius: 24px;
  background-color: var(--nj-panel);
  justify-content: center;

  @media only screen and (max-width: $nj-breakpoint-lower-than-desktop) {
    width: 40px;
    height: 40px;
    border-radius: 20px;
  }
}

.nj-mobile-action-panel.nj-mobile-modal-overlay-list-panel {
  background-color: var(--nj-panel);
  max-height: 80%;
  overflow-y: auto;
  padding: 0;
}

.nj-mobile-modal-overlay-list-panel {
  .nj-mobile-modal-overlay-list-panel-header-wrapper {
    background-color: var(--nj-panel);
    z-index: 5;
    position: sticky;
    top: 0;
    padding: 8px 8px 0 8px;
  }

  .nj-modal-overlay-mobile-list-panel-header {
    position: sticky;
    background-color: var(--nj-panel);
    top: 16px;
    padding: 10px 16px;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    gap: 16px;
    color: var(--nj-foreground--secondary);
    z-index: 3;
  }
}

.nj-modal-overlay-mobile-list-panel {
  box-sizing: border-box;
  flex-direction: column;
  display: flex;
  position: relative;

  .nj-modal-overlay-mobile-list-panel-body {
    padding: 8px 16px;
    box-sizing: border-box;

    &.isGrid {
      gap: 8px;
      overflow-y: scroll;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }

    &.isGeneratedImagesGrid {
      gap: 8px;
      overflow-y: scroll;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }

    & .nj-thread-research-media--thumbnail {
      width: 100%;
    }

    & button {
      width: 100%;
      max-width: 100%;
      border-radius: 8px;
      padding: 10px 8px;
    }
  }
}
