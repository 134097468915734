@import 'src/styles/breakpoints';
@import 'src/styles/placeholders/reset-placeholders';
@import 'src/styles/placeholders/flex-placeholders';
@import 'src/styles/placeholders/common-placeholders';

.root {
  @extend %reset-typography;
  @extend %flex-row;

  box-sizing: border-box;
  padding: 10px 16px;
  border-radius: 12px;
  background-color: var(--nj-panel);
  max-width: 130px;
  flex-shrink: 0;
  cursor: pointer;

  @media only screen and (min-width: $nj-breakpoint-tablet) {
    max-width: 160px;
  }
}

.index {
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 0.2px;
  color: var(--nj-foreground--secondary);
}

.title {
  @extend %ellipsis;

  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--nj-foreground--secondary);
}
