.nj-thread-image-generation-media-wrapper {
  position: relative;
  margin-top: 16px;

  .nj-thread-research-media--slide {
    width: 160px;
    max-width: 160px;
    height: 160px;
  }

  .nj-thread-research-media--thumbnail {
    width: 160px;
    height: 160px;

    .react-loading-skeleton {
      border-radius: 16px;
      box-sizing: border-box;
      line-height: unset;
    }
  }

  .nj-thread-research-media--thumbnail-image {
    border-radius: 16px;
  }

  .nj-thread-research-media--thumbnail-icon.centered {
    top: 56px;
    left: 56px;
    width: 48px;
    height: 48px;
    border-radius: 50%;
  }
}
