.root {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 8px 0 8px 8px;
  border-bottom: 1px dashed var(--nj-border);
  width: 100%;
  justify-content: flex-start;
}

.content {
  flex-grow: 1;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.icon {
  padding: 6px;
  flex-shrink: 0;
}
