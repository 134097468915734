.nj-message {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 0 auto;
  width: 100%;
  padding: 0px 16px;
  padding-bottom: 40px;

  @media screen and (min-width: $nj-breakpoint-tablet) {
    max-width: 780px;
    padding: 0;
  }
}

.nj-message--header,
.nj-message--subheader {
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 16px;
}

.nj-message--header {
  justify-content: start;

  h3 {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
  }

  img {
    border-radius: 50%;
    background-color: var(--nj-ninja-loader);
  }
}

.nj-message--header-query-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.nj-message-header-with-file-wrapper--file-label {
  display: flex;
  gap: 8px;
  max-width: 200px;
  padding: 10px 16px;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  background-color: var(--nj-panel);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  box-sizing: border-box;
}

.nj-message-header-with-file-wrapper--file-label-text {
  display: flex;
  gap: 8px;
}

.nj-message-header-with-file-wrapper--file-name {
  max-width: 148px;
}

.nj-message--subheader {
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  width: 100%;
}

.nj-message--body {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.nj-message--date {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: var(--nj-foreground--secondary);
}

.nj-message--links {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.react-loading-skeleton {
  border-radius: 16px;
}
