.nj-thread-research-card {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 16px;

  // only influence top level dropdowns ul
  & > ul,
  &--center-content-wrapper > ul {
    margin: 0;
    padding-left: 0;
    list-style: none;
  }

  &--center-content-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 0 8px;
  }

  &--deep-research {
    display: flex;
    padding: 8px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 8px;
    background: linear-gradient(
      180deg,
      var(--nj-progress-button-background) 0%,
      rgba(var(--nj-jean-blue-50--rgb), 0) 50%
    );

    svg {
      color: var(--nj-disco-blue-600);
    }
  }

  &--deep-research {
    .nj-thread-message--content {
      p,
      div,
      span,
      li {
        white-space: unset;
      }

      p {
        //margin: 16px 0 !important;
        margin: 0;
        line-height: 28px;
        padding: 0;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      ol {
        margin: 0 0 0 24px;
        padding: 0;
        list-style-position: outside;
        list-style-type: decimal !important;

        li {
          margin: 0;
          padding: 0;
        }
      }

      ul {
        margin: 0 0 0 24px;
        padding: 0;
        list-style-type: disc !important;
        list-style-position: outside;

        li {
          margin: 0;
          padding: 0;
          color: var(--nj-foreground--secondary);
        }
      }

      a {
        color: var(--nj-foreground--secondary);
      }

      table {
        width: inherit;
        border-collapse: separate;
        border-spacing: 0;

        tr th,
        tr td {
          padding: 8px 16px !important;
          border-right: 1px solid var(--nj-border);
          border-bottom: 1px solid var(--nj-border);
        }

        tr th:first-child,
        tr td:first-child {
          border-left: 1px solid var(--nj-border);
        }

        tr th {
          border-top: 1px solid var(--nj-border);
          text-align: left;
        }

        tr:first-child th:first-child {
          border-top-left-radius: 8px;
        }

        tr:last-child td:first-child {
          border-bottom-left-radius: 8px;
        }

        tr:first-child th:last-child {
          border-top-right-radius: 8px;
        }

        tr:last-child td:last-child {
          border-bottom-right-radius: 8px;
        }
      }
    }
  }

  &--deep-research-title {
    background: linear-gradient(90deg, #0aa7d9 0%, #0a74ff 50%, #3844a8 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    display: flex;
    align-items: center;
    gap: 8px;
    padding-top: 8px;
    margin: 0;
  }

  &--summary {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &--summary-header {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    width: 100%;
    gap: 8px;
  }

  &--summary-title {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    display: flex;
    gap: 8px;
    align-items: center;
  }

  &--ninja-llm-title {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 8px;
    margin: 0;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
  }

  &--source {
    padding-bottom: 16px;
    border-bottom: 1px solid var(--nj-border);
  }

  &--source-progress-bar .nj-thread-combined-card--progress-bar-skeleton {
    max-width: 320px;
    margin-top: 8px;
  }

  &--source-progress-bar
    .nj-thread-combined-card--progress-bar-skeleton:first-child {
    max-width: 480px;
  }

  &--source-progress-bar
    .nj-thread-combined-card--progress-bar-skeleton:nth-last-child(2) {
    max-width: 160px;
  }

  &--deep-research-progress-bar {
    margin: 8px 0 0;
    width: 100%;

    .nj-thread-combined-card--progress-bar-skeleton {
      max-width: 320px;
      margin-top: 8px;
    }

    .nj-thread-combined-card--progress-bar-skeleton:first-child {
      max-width: 480px;
    }

    .nj-thread-combined-card--progress-bar-skeleton:nth-last-child(2) {
      max-width: 160px;
    }
  }

  &--progress-bar {
    margin: 0;

    & .nj-thread-combined-card--progress-bar-skeleton {
      max-width: unset;
      margin: 0;
    }
  }

  &--source-header {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }

  &--source-title {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    display: flex;
    gap: 8px;
  }

  &--source-header {
    padding-top: 16px;
  }

  &--source-content {
    padding-left: 32px;
  }

  &--show-more-button {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 80px;
    padding: 10px 16px;
    gap: 8px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;

    &.with-margin {
      margin-left: 16px;
    }
  }

  &--collapsed-content-block-wrapper {
    position: relative;
    cursor: pointer;
  }

  &--collapsed-content-block {
    max-height: 88px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &--collapsed-content-block-coverage {
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: linear-gradient(
      180deg,
      rgba(var(--nj-background--rgb), 0) 0%,
      var(--nj-background) 100%
    );
  }

  &--references {
    position: relative;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &--references-header {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    cursor: pointer;
  }

  &--references-header-title {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: var(--nj-foreground--secondary) !important;
  }

  &--references-slide {
    width: 160px;
    max-width: 160px;
    flex-shrink: 0;
    box-sizing: border-box;
  }

  &--reference-item {
    background-color: var(--nj-panel);
    border-radius: 12px;
    box-sizing: border-box;
    flex-shrink: 0;
    padding: 10px 16px;
    width: 160px;

    .nj-thread-research-card--reference-link {
      box-sizing: border-box;
      color: var(--nj-foreground);
      display: flex;
      flex-direction: column;
      gap: 4px;
      text-decoration: none;

      p {
        margin: 0;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  &--reference-item-index {
    font-size: 10px;
    font-weight: 600;
    line-height: 12px;
    letter-spacing: 0.2px;
    color: var(--nj-foreground--secondary);
  }

  &--reference-item-title {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: var(--nj-foreground--secondary);
  }

  &--reference-item-subtitle {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }

  &--reference-header {
    display: flex;
    gap: 8px;
    align-items: center;

    img {
      flex-shrink: 0;
    }
  }

  &--reference-domain {
    color: var(--nj-foreground--secondary);
  }

  &--media {
    max-width: 768px;
    overflow: hidden;
  }

  &--carousel {
    box-sizing: border-box;
    margin-top: 10px;
    width: 100%;
    max-width: 100%;

    .swiper-scrollbar {
      display: none;
    }

    .swiper-wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
    }
  }

  &--carousel-navigation-wrapper {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    flex-direction: row;
    gap: 16px;

    & button.swiper-button-disabled {
      opacity: 0.38;
      cursor: not-allowed;
    }
  }

  &--carousel-container {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;

    .swiper-wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
    }

    .swiper-button-prev,
    .swiper-button-next {
      position: absolute;
      top: 76px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: var(--nj-button-fab--background);
      color: var(--nj-button-fab--foreground);
      transform: translateY(-50%);
      box-shadow: 0px 1px 4px 0px var(--nj-deep-shadow);
      cursor: pointer;
    }

    .swiper-button-disabled {
      pointer-events: visible;
      display: none;
    }

    .swiper-button-prev::after,
    .swiper-button-next::after {
      font-size: 20px;
    }
  }

  &--references-list {
    .swiper-button-prev,
    .swiper-button-next {
      top: 46px;
    }
  }

  .divider {
    margin-top: 0;
  }

  .deep-research-divider {
    margin: 0;
    position: relative;
    top: -12px;
  }

  &--summary-llm-title-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    margin-top: 8px;
  }

  &--summary-llm-description {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: var(--nj-foreground--secondary);
  }

  &--summary-llm-title {
    display: flex;
    gap: 16px;
    align-items: center;
  }

  &--summary-llm-title-text {
    position: relative;
  }

  &--ninja-icon {
    position: relative;
    top: -3px;
  }

  .nj-thread-research-media {
    position: relative;
    margin-top: 24px;
    padding: 0 0 8px;

    .nj-thread-research-card--summary-title {
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      color: var(--nj-foreground--secondary);
      text-transform: uppercase;
    }

    .nj-thread-research-card--carousel {
      margin-top: 12px;
    }
  }

  .divider {
    margin: 8px 0;
    border-style: solid;
    border-color: var(--nj-border);
    box-shadow: none;
    border-bottom-width: 0;
  }
}
