.nj-thread-research-media {
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  margin-top: 16px;

  &--tabs {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
  }

  &--tabs-button {
    padding: 4px 8px;
    display: flex;
    gap: 8px;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    border-radius: 16px;
    border: 1px solid var(--nj-border);

    &.active {
      border: 1px solid var(--nj-mode-button-background);
      background-color: var(--nj-mode-button-background);
      color: var(--nj-foreground--link);
    }
  }

  &--aspect-ratio-container {
    box-sizing: border-box;
    width: 100%;
    max-width: 100%;
    position: relative;

    &::before {
      box-sizing: border-box;
      content: '';
      display: block;
      padding-top: 54.69%;
    }
  }

  &--aspect-ratio-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 8px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    & > div {
      overflow: hidden;
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
    }
  }

  &--slide {
    width: 112px;
    max-width: 112px;
    height: 112px;
    flex-shrink: 0;
    box-sizing: border-box;
  }

  &--thumbnail {
    display: block;
    width: 112px;
    height: 112px;
    flex-shrink: 0;
    position: relative;
    cursor: pointer;

    .react-loading-skeleton {
      border-radius: 8px;
      box-sizing: border-box;
      line-height: unset;
    }
    &.placeholder {
      background-color: var(--nj-panel);
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;

      .nj-thread-research-media--thumbnail-icon.centered {
        position: relative;
        top: unset;
        left: unset;
      }

      .nj-thread-research-media--thumbnail-placeholder-text {
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        text-align: center;
      }
    }
  }

  &--thumbnail-icon {
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 16px;
    background: var(--nj-nav--pressed-background);
    color: var(--nj-foreground);
    z-index: 1;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &--thumbnail-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }
}
