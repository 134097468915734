.root {
  ul {
    padding: 0;
  }

  margin-top: 8px;
}

.header,
.collapseButton {
  display: flex;
  align-items: center;
  gap: 8px;
}

.header {
  padding: 8px 0;
  width: 100%;

  &::before,
  &::after {
    content: '';
    flex-grow: 1;
    height: 1px;
    background-color: var(--nj-border);
  }
}

.collapseButton {
  padding: 0 8px;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-transform: uppercase;
  color: var(--nj-foreground--secondary);
  flex-grow: 0;
}
