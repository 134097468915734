@import 'src/styles/placeholders/flex-placeholders';

.root {
  @extend %flex-column;

  padding: 8px;
  border-radius: 12px;
  align-items: flex-start;
  align-self: stretch;
}

.root.plain {
  .title {
    color: var(--nj-foreground);
  }

  .collapsedContentCoverage {
    background: linear-gradient(
      180deg,
      rgba(var(--nj-background--rgb), 0) 0%,
      var(--nj-background) 100%
    );
  }
}

.root.blue {
  background: linear-gradient(
    180deg,
    var(--nj-progress-button-background) 0%,
    rgba(var(--nj-jean-blue-50--rgb), 0) 50%
  );

  .title {
    background: linear-gradient(
      90deg,
      var(--nj-disco-blue-600) 0%,
      var(--nj-jean-blue-500) 50%,
      var(--nj-jean-blue-650) 100%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    svg {
      color: var(--nj-disco-blue-600);
    }
  }
}

.root.light-blue {
  background: var(--nj-external-researcher-linear-gradient);

  .title {
    color: var(--nj-tags-chips--research-title);
  }
}

.title {
  @extend %flex-row;

  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  padding: 4px 0;
  margin: 0;
}

.collapsedContentBlock {
  max-height: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}

.collapsedContentCoverage {
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  //TODO(olha): check gradient
  background: linear-gradient(
    180deg,
    rgba(var(--nj-background--rgb), 0) 0%,
    var(--nj-background) 100%
  );
}
