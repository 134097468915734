%flex-column {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

%flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

%flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

%flex-between {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}
